import { template as template_06b30a94dd9f4765a2a6bcdf55a53ef3 } from "@ember/template-compiler";
const WelcomeHeader = template_06b30a94dd9f4765a2a6bcdf55a53ef3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
