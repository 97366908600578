import { template as template_7b37ad903ed0434a955ebdef89230eac } from "@ember/template-compiler";
const FKLabel = template_7b37ad903ed0434a955ebdef89230eac(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
