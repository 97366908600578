import { template as template_8c82acb0c5af42a6a63e348ed85a91cc } from "@ember/template-compiler";
const FKText = template_8c82acb0c5af42a6a63e348ed85a91cc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
