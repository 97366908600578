import { template as template_3ced6f53ea5d436680a6c61483c2dca2 } from "@ember/template-compiler";
import dirSpan from "discourse/helpers/dir-span";
import { i18n } from "discourse-i18n";
const TopicExcerpt = template_3ced6f53ea5d436680a6c61483c2dca2(`
  {{#if @topic.hasExcerpt}}
    <a href={{@topic.url}} class="topic-excerpt">
      {{dirSpan @topic.escapedExcerpt htmlSafe="true"}}

      {{#if @topic.excerptTruncated}}
        <span class="topic-excerpt-more">{{i18n "read_more"}}</span>
      {{/if}}
    </a>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TopicExcerpt;
